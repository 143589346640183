/* eslint-disable react/jsx-filename-extension */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { useSelector, useDispatch } from "react-redux";
import _merge from "lodash/merge";

import SectionDescription from "components/lib/Shared/SectionDescription";
import { updateUserState } from "state/slices/user";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
} from "components/lib/Shared/Icons/sflIcons";
import PostalCodeForm from "components/PLCTABS/CompanyOfficialsTab/PostalComponents/PostalCodeForm";
import PostalAddressFormLocal from "components/PLCTABS/CompanyOfficialsTab/PostalComponents/PostalAddressFormLocal";
import PostalAddressFormForeign from "components/PLCTABS/CompanyOfficialsTab/PostalComponents/PostalAddressFormForeign";
import ValidationError from "components/lib/Shared/ValidationError";
import residentialDetailsValidation from "./validations/residentialDetailsValidation";
import Button from "components/lib/Shared/Button";
import ElectronicAddress from "components/PLCTABS/CompanyOfficialsTab/AddressParticularsComponents/ElectronicAddress";
import { Constants } from "config/constants";

const ResidentialDetails = ({
  handleBackBtn,
  handleNextBtn,
  isCoApplicant,
}) => {
  const [validationSchema, setValidationSchema] = useState(null);
  const [initialValues, setInitialValues] = useState({});
  const { transformedComments } = useSelector(
    (state) => state.commentsSlice.profileComments
  );
  const { idType, isNonResident, postalAddress, electronicAddress, email } =
    useSelector((state) => state.userSlice);
  const dispatch = useDispatch();

  const handleSubmit = (formData) => {
    dispatch(updateUserState(formData));
    handleNextBtn();
  };

  useEffect(() => {
    const { schemaInitialValues, schema } = residentialDetailsValidation({
      idType,
      isCoApplicant,
    });
    const updatedElectronicAddress = {
      ...electronicAddress,
      emailAddress: electronicAddress?.emailAddress || email,
    };
    setValidationSchema(schema);
    setInitialValues(
      _merge(schemaInitialValues, {
        isNonResident: "No",
        postalAddress,
        electronicAddress: updatedElectronicAddress,
      })
    );
  }, [idType, isNonResident, postalAddress, electronicAddress]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...initialValues,
        comments: transformedComments,
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      {({ values, errors, touched }) => (
        <Form>
          <SectionDescription
            id="Step: 3 of 5"
            title="Residential Details"
            description="Please provide the correct details. The information provided will be used to process your application."
          />
          {idType === Constants.ID_TYPES.KENYAN_CITIZEN && (
            <div className="mt-4 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md">
              <div className="px-4 py-6 space-y-2 sm:p-6">
                <span>Is this Official Non-Resident?</span>
                <div className="flex space-x-2">
                  <div className="flex items-center space-x-2">
                    <label
                      htmlFor="yesResident"
                      className="block text-sm font-medium text-gray-700"
                    >
                      YES
                    </label>
                    <Field
                      type="radio"
                      name="isNonResident"
                      id="yesResident"
                      value="Yes"
                    />
                  </div>
                  <div className="flex items-center space-x-2">
                    <label
                      htmlFor="notResident"
                      className="block text-sm font-medium text-gray-700"
                    >
                      NO
                    </label>
                    <Field
                      type="radio"
                      name="isNonResident"
                      id="notResident"
                      value="No"
                    />
                  </div>
                </div>
                <ValidationError
                  errors={errors}
                  touched={touched}
                  name="isNonResident"
                />
              </div>
            </div>
          )}
          {(idType === Constants.ID_TYPES.FOREIGNER ||
            (idType === Constants.ID_TYPES.KENYAN_CITIZEN &&
              values.isNonResident === "Yes")) &&
            !isCoApplicant && (
              <>
                <div className="px-4 mt-4 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md sm:p-6">
                  <Field
                    name="postalAddress"
                    component={PostalAddressFormForeign}
                  />
                </div>
              </>
            )}
          {((idType === Constants.ID_TYPES.KENYAN_CITIZEN &&
            values.isNonResident === "No") ||
            idType === Constants.ID_TYPES.FOREIGN_RESIDENT) &&
            !isCoApplicant && (
              <>
                <div className="px-4 mt-4 space-y-4 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md sm:p-6">
                  <Field
                    name="postalAddress"
                    component={PostalAddressFormLocal}
                    idType={idType}
                  />
                  <Field name="postalAddress" component={PostalCodeForm} />
                </div>
              </>
            )}
          <Field
            name="electronicAddress"
            component={ElectronicAddress}
            disableEmailAddress
          />

          <div className="flex items-center justify-between mt-6">
            <Button
              variant="backBtn"
              onClick={handleBackBtn}
              preIcon={ChevronLeftIcon}
            >
              Back to previous step
            </Button>
            <Button type="submit" postIcon={ChevronRightIcon}>
              Save and proceed
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ResidentialDetails;
