import { useEffect, useState, useMemo } from "react";
import { Formik, Form, Field } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import _pickBy from "lodash/pickBy";
import _isEmpty from "lodash/isEmpty";
import _merge from "lodash/merge";
import _get from "lodash/get";
import _orderBy from "lodash/orderBy";

import { getBusinessDocumentData } from "components/PLCTABS/CompanyOfficialsTab/FileUploadForm/documentData";
import FileUploadForm from "components/PLCTABS/CompanyOfficialsTab/FileUploadForm";
import Button from "components/lib/Shared/Button";
import businessDocumentsValidation from "./businessDocumentsValidation";
import { useToast } from "hooks/useToast";
import {
  downloadBusinessDocumentsAsync,
  updateCompanyAsync,
  updateCompanyState,
} from "state/slices/company";
import { handleFilesUpload } from "utils/filesUtils";
import {
  CORPORATES,
  INDIVIDUALS,
} from "components/PLCTABS/SFLdata/config/constants";
import { calculateCumulativeOwnership, groupBeneficialOwners } from "utils";
import useFullscreenToggle from "hooks/useFullscreenToggle";

function SignOff({ submitBtnLabel, companyOfficials }) {
  const [isDownloading, setIsDownloading] = useState(false);
  const [confirmDocumentsSigned, setConfirmDocumentsSigned] = useState(false);

  const { company } = useSelector((state) => state.companySlice);
  const currentUser = useSelector((state) => state.userSlice);
  const { transformedComments } = useSelector((state) => state.commentsSlice);
  const beneficialOwners = useSelector(
    (state) => state.beneficialOwnersTableSlice.beneficialOwners
  );
  const { toast } = useToast();
  const dispatch = useDispatch();

  const isCompanySecretaryExist = companyOfficials.some(
    (official) => official.designation === "Company Secretary"
  );
  const history = useHistory();
  const { isFullscreen, ToggleFullscreenButton } = useFullscreenToggle();

  const { fileUploads, preSignedBusinessDocuments, createdBy } = company;

  const isApplicant = createdBy?._id === currentUser._id;

  const reportableBO = useMemo(() => {
    let tempReportableBO = [];
    let tempNonReportableBO = [];
    if (beneficialOwners.length > 0) {
      beneficialOwners.forEach((bo) => {
        const {
          directPercentShareholding,
          indirectPercentShareholding,
          directPercentVotingRights,
          indirectPercentVotingRights,
          directRightRemoveDirector,
          indirectRightRemoveDirector,
          directCompanyControlRight,
          indirectCompanyControlRight,
        } = bo?.beneficialOwnershipForm ?? {};

        const isDirectBeneficiary =
          directPercentShareholding >= 10 ||
          directPercentVotingRights >= 10 ||
          directRightRemoveDirector === "yes" ||
          directCompanyControlRight === "yes";
        const isIndirectBeneficiary =
          indirectPercentShareholding >= 10 ||
          indirectPercentVotingRights >= 10 ||
          indirectRightRemoveDirector === "yes" ||
          indirectCompanyControlRight === "yes";

        // hide eleventh minority entry on BO table
        if (bo.idType === "Minority") {
          bo = {
            ...bo,
            hidden: true,
          };
        }

        if (!bo?.hidden === true) {
          const parentBO = bo?.shareholderWithBO
            ? beneficialOwners.find(
                (official) => official._id === bo.shareholderWithBO
              )
            : {};
          if (
            INDIVIDUALS.includes(bo.idType) &&
            bo.isReportable &&
            (CORPORATES.includes(parentBO.idType)
              ? isDirectBeneficiary
              : isDirectBeneficiary || isIndirectBeneficiary) &&
            bo.isEstateDistributionDetermined !== "No" &&
            bo.idType !== "Estate"
          ) {
            tempReportableBO.push(bo);
          } else tempNonReportableBO.push(bo);
        }
      });
    }

    const verifyKeys = [
      "identificationDetails.nationalIDNumber",
      "identificationDetails.foreignCertificateID",
      "identificationDetails.passportNumber",
      "identificationDetails.birthCertificateNumber",
    ];

    let filteredTempReportableBOs = [...tempReportableBO];
    let filteredTempNonReportableBOs = [...tempNonReportableBO];
    // if one of the multiple instances is reportable shift non-reportable instances to reportable table
    verifyKeys.map((verifyKey) => {
      tempNonReportableBO.map((bo) => {
        // check if current bo has reportable entry/instance
        const hasReportableInstance = tempReportableBO.find(
          (reportableBoRow) =>
            _get(reportableBoRow, verifyKey) &&
            _get(reportableBoRow, verifyKey) === _get(bo, verifyKey)
        );
        if (hasReportableInstance) {
          filteredTempReportableBOs.push(bo);
          const boIndex = filteredTempNonReportableBOs.findIndex(
            (bo) =>
              _get(bo, verifyKey) === _get(hasReportableInstance, verifyKey)
          );
          filteredTempNonReportableBOs.splice(boIndex, 1);
        }
      });
    });

    filteredTempReportableBOs = groupBeneficialOwners(
      _orderBy(
        filteredTempReportableBOs,
        ["shareholderWithBO", "linkToCompany"],
        ["desc", "desc"]
      )
    );

    filteredTempNonReportableBOs = groupBeneficialOwners(
      _orderBy(
        filteredTempNonReportableBOs,
        ["shareholderWithBO", "linkToCompany"],
        ["desc", "desc"]
      )
    );

    tempNonReportableBO = filteredTempNonReportableBOs;

    const reportableBOAfterCumulative = tempNonReportableBO
      .filter((bo) => INDIVIDUALS.includes(bo.idType))
      .filter((bo) => {
        const { cumulativeShareholdings, cumulativeVotings } =
          calculateCumulativeOwnership(bo, beneficialOwners);
        if (cumulativeShareholdings >= 10 || cumulativeVotings >= 10) {
          const indexOfBO = tempNonReportableBO.findIndex(
            (beneficialOwner) => beneficialOwner._id === bo._id
          );
          filteredTempNonReportableBOs.splice(indexOfBO, 1);
          return true;
        }
      });

    return [...filteredTempReportableBOs, ...reportableBOAfterCumulative];
  }, [beneficialOwners]);

  useEffect(() => {
    setConfirmDocumentsSigned(company.confirmDocumentsSigned);
  }, [company.confirmDocumentsSigned]);

  const handleSubmit = async (formData) => {
    if (!isApplicant) {
      history.push("/");
      return;
    }

    let status = "BRS Review";
    if (company?.status === "BRS Corrections") {
      status = "BRS Review II";
    }

    const uploadedFiles = await handleFilesUpload(formData.fileUploads);
    const companyData = {
      fileUploads: {
        ..._pickBy(formData.fileUploads, (file) => typeof file === "string"),
        ...uploadedFiles,
      },
      status,
      confirmDocumentsSigned,
    };
    dispatch(updateCompanyState(companyData));
    await dispatch(
      updateCompanyAsync({
        id: company?._id,
        data: companyData,
      })
    );
    toast("success", "Application successfully submitted to the admins.");
    history.push("/");
  };

  const documents = getBusinessDocumentData();
  const filteredDocuments = documents.filter((doc) => {
    if (!isCompanySecretaryExist && company.type === "PVT") {
      return doc.name !== "formCR10" && doc.name !== "formCR12";
    }
    return true;
  });
  const { schemaInitialValues, schema } = businessDocumentsValidation(
    documents,
    reportableBO.length,
    isCompanySecretaryExist
  );
  const initialValues = _merge(schemaInitialValues, {
    fileUploads,
    comments: transformedComments,
  });

  return (
    <div className="col-span-6 md:border-l md:col-span-5 md:pl-8 pt-8 border-gray">
      <div
        className={`${
          isFullscreen
            ? "fixed inset-0 bg-white px-4 top-[7.5rem] z-50"
            : "w-full"
        }`}
      >
        <ToggleFullscreenButton />
        <div
          className={`flex space-x-2 w-full ${
            isFullscreen ? "h-[80vh] overflow-auto" : ""
          }`}
        >
          <Formik
            enableReinitialize
            initialValues={{
              fileUploads: preSignedBusinessDocuments,
            }}
          >
            {() => {
              const handleDownloadDocuments = async () => {
                try {
                  setIsDownloading(true);
                  const businessDocuments = [];

                  filteredDocuments.forEach((businessDocument) => {
                    const fileKey =
                      company.preSignedBusinessDocuments[businessDocument.name];
                    if (fileKey) {
                      businessDocuments.push({
                        key: fileKey,
                        name: `${businessDocument.label}.${fileKey
                          .split(".")
                          .pop()}`,
                      });
                    }
                  });

                  await Promise.all(
                    businessDocuments.map(async (businessDocument) => {
                      return dispatch(
                        downloadBusinessDocumentsAsync({
                          companyId: company._id,
                          businessDocument,
                        })
                      );
                    })
                  );
                } catch (error) {
                  toast(
                    "error",
                    error?.message ?? "Something went wrong, please try again"
                  );
                } finally {
                  setIsDownloading(false);
                }
              };

              return (
                <Form className="flex-grow">
                  {!_isEmpty(company.preSignedBusinessDocuments) ? (
                    <div className="px-4 mt-4 space-y-6 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md sm:p-6">
                      <div className="flex">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                          Unsigned
                        </h3>
                        {isApplicant && (
                          <Button
                            className="ml-auto"
                            onClick={handleDownloadDocuments}
                            isLoading={isDownloading}
                            loadingText="Downloading..."
                          >
                            Download documents
                          </Button>
                        )}
                      </div>
                      <div>
                        <Field
                          name="fileUploads"
                          component={FileUploadForm}
                          documentData={filteredDocuments}
                          onlyPreview
                          showDocumentLabel
                        />
                      </div>
                    </div>
                  ) : (
                    "No documents found"
                  )}
                </Form>
              );
            }}
          </Formik>

          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => {
              return (
                <Form className="flex-grow">
                  <div className="px-4 mt-4 space-y-6 bg-white rounded-sm shadow-sm sm:rounded md:rounded-md sm:p-6">
                    <div className="flex">
                      <h3 className="text-lg font-medium leading-6 text-gray-900">
                        Uploaded Incorporation Forms (Signed)
                      </h3>
                    </div>
                    <div>
                      <Field
                        name="fileUploads"
                        component={FileUploadForm}
                        documentData={filteredDocuments}
                        onlyPreview={!isApplicant}
                        showDocumentLabel
                      />
                    </div>
                  </div>
                  <div className="flex items-center my-2">
                    <input
                      className="mr-4 align-top transition duration-200 bg-transparent bg-center bg-no-repeat bg-contain border rounded appearance-none cursor-pointer border-tertiary checked:bg-blue-600 checked:border-blue-600 focus:outline-none"
                      type="checkbox"
                      checked={confirmDocumentsSigned}
                      onChange={(e) =>
                        setConfirmDocumentsSigned(e.target.checked)
                      }
                      id="flexCheckDefault"
                    />
                    <label
                      className="inline-block text-tertiary"
                      htmlFor="flexCheckDefault"
                    >
                      I confirm all documents have been correctly signed.
                    </label>
                  </div>
                  <div className="flex justify-end mt-2">
                    <Button
                      type="submit"
                      isLoading={isSubmitting}
                      disabled={!confirmDocumentsSigned}
                      loadingText="Submitting..."
                    >
                      {submitBtnLabel}
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default SignOff;
