import { useState, useMemo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import _get from "lodash/get";

import SelectIdType from "./SelectIdType";
import PersonalInformation from "./PersonalInformation";
import ResidentialDetails from "./ResidentialDetails";
import UploadDocuments from "./UploadDocuments";
import ConfirmDetails from "./ConfirmDetails";
import personalInformationValidation from "./validations/personalInformationValidation";
import residentialDetailsValidation from "components/PLCTABS/CompanyOfficialsTab/AssembledCompanyOfficialForms/commonValidationSchema/residentialDetailsValidation";
import { getDocumentData } from "components/PLCTABS/CompanyOfficialsTab/FileUploadForm/documentData";
import uploadDocumentsValidation from "./validations/uploadDocumentsValidation";
import { getAccessRequests } from "state/slices/accessRequest";
import { classNames } from "utils";

export default function OnBoarding() {
  const [currentSection, setCurrentSection] = useState(0);
  const [isCoApplicant, setIsCoApplicant] = useState(false);
  const [validTabs, setValidTabs] = useState({
    selectIdType: false,
    personalInformation: false,
    residentialDetails: false,
    uploadDocuments: false,
  });
  const currentUser = useSelector((state) => state.userSlice);
  const dispatch = useDispatch();
  const accessRequests = useSelector(
    (state) => state.accessRequestsSlice.items
  );

  const { idType = "", isNonResident } = currentUser;
  const { transformedComments } = useSelector(
    (state) => state.commentsSlice.profileComments
  );

  useEffect(() => {
    dispatch(
      getAccessRequests({
        query: {
          initiatedToEmail:
            currentUser.electronicAddress?.emailAddress || currentUser.email,
        },
      })
    );
  }, [dispatch, currentUser]);

  useEffect(() => {
    if (accessRequests.length > 0) {
      const filteredAccessRequestForCoApplicant = accessRequests.find(
        (accessRequest) =>
          accessRequest.initiatedToEmail &&
          !accessRequest?.isActive &&
          accessRequest?.status === "Pending" &&
          Constants.ACCESS_REQUEST.CO_APPLICANT === accessRequest.requestFor
      );
      setIsCoApplicant(
        filteredAccessRequestForCoApplicant?.appointedAs ===
          Constants.PROFILE_TYPES.CO_APPLICANT
      );
    }
  }, [accessRequests]);
  const handleBackBtn = () => {
    if (currentSection - 1 >= 0) setCurrentSection(currentSection - 1);
  };

  const handleNextBtn = () => {
    setCurrentSection(currentSection + 1);
  };

  useEffect(() => {
    const tempValidTabs = {
      selectIdType: false,
      personalInformation: false,
      residentialDetails: false,
      uploadDocuments: false,
    };
    const checkSidebarNavigationValidation = async () => {
      tempValidTabs.selectIdType = Boolean(idType);
      tempValidTabs.personalInformation = await personalInformationValidation(
        idType
      ).schema.isValid(currentUser);
      tempValidTabs.residentialDetails = await residentialDetailsValidation({
        idType,
        isNonResident,
      }).schema.isValid(currentUser);
      tempValidTabs.uploadDocuments = await uploadDocumentsValidation(
        getDocumentData(idType)
      ).schema.isValid(currentUser);
      setValidTabs(tempValidTabs);
    };
    checkSidebarNavigationValidation();
  }, [currentUser]);

  const navigation = useMemo(() => {
    const namesCommentCount = _get(
      transformedComments?.names,
      "commentsCount",
      0
    );
    const identificationDetailsCommentsCount = _get(
      transformedComments?.identificationDetails,
      "commentsCount",
      0
    );
    const electronicAddressCommentsCount = _get(
      transformedComments?.electronicAddress,
      "commentsCount",
      0
    );
    const postalAddressCommentsCount = _get(
      transformedComments?.postalAddress,
      "commentsCount",
      0
    );
    const residentialAddressDetailsCommentsCount = _get(
      transformedComments?.residentialAddressDetails,
      "commentsCount",
      0
    );

    return [
      { name: "1. Select ID Type" },
      {
        name: "2. Add your personal details",
        commentsCount: namesCommentCount + identificationDetailsCommentsCount,
        disabled: !validTabs.selectIdType,
      },
      {
        name: "3. Residential details",
        commentsCount:
          electronicAddressCommentsCount +
          residentialAddressDetailsCommentsCount +
          postalAddressCommentsCount,
        disabled: !validTabs.personalInformation || !idType,
      },
      {
        name: "4. Upload your documents",
        commentsCount: _get(
          transformedComments?.uploadDocuments,
          "commentsCount",
          0
        ),
        disabled:
          !validTabs.personalInformation || !validTabs.residentialDetails,
      },
      {
        name: "5. Confirm your details",
        disabled:
          !validTabs.personalInformation ||
          !validTabs.residentialDetails ||
          !validTabs.uploadDocuments,
      },
    ];
  }, [transformedComments, validTabs]);

  return (
    <div className="my-16">
      <h1 className="my-4 text-2xl font-bold leading-7 text-gray-900 sm:text-3xl">
        Welcome to Simple Formations
      </h1>
      <div className="grid w-[768px]gap-4 mb-16 ">
        <p>
          Our mission is to ensure that our clients have an efficient and easier
          business application process.{" "}
        </p>
        <p>
          Follow the steps below to ensure we have a complete profile to work
          with.
        </p>
      </div>

      <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
        <aside className="px-2 py-6 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
          <nav className="flex flex-col space-y-1">
            {navigation.map((item, index) => (
              <button
                key={item.name}
                className={classNames(
                  index === currentSection
                    ? "bg-gray-50 text-sflPrimary hover:bg-white"
                    : "rounded-md px-3 py-2 text-tertiary flex items-center text-base font-medium",
                  "flex items-center px-3 py-2 text-base font-medium hover:text-sflPrimary hover:bg-gray-50 disabled:cursor-not-allowed disabled:text-gray-300"
                )}
                onClick={() => setCurrentSection(index)}
                disabled={item.disabled}
              >
                <span className="relative truncate">
                  {item.name}
                  {item?.commentsCount > 0 && (
                    <span className="absolute flex items-center justify-center h-4 p-1 text-white bg-red-700 rounded-full -top-3 -right-4">
                      <span>{item.commentsCount}</span>
                    </span>
                  )}
                </span>
              </button>
            ))}
          </nav>
        </aside>

        <div className="px-2 space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
          {currentSection === 0 && (
            <SelectIdType handleNextBtn={handleNextBtn} />
          )}
          {currentSection === 1 && (
            <PersonalInformation
              handleBackBtn={handleBackBtn}
              handleNextBtn={handleNextBtn}
            />
          )}
          {currentSection === 2 && (
            <ResidentialDetails
              handleBackBtn={handleBackBtn}
              handleNextBtn={handleNextBtn}
              isCoApplicant={isCoApplicant}
            />
          )}
          {currentSection === 3 && (
            <UploadDocuments
              handleBackBtn={handleBackBtn}
              handleNextBtn={handleNextBtn}
            />
          )}
          {currentSection === 4 && (
            <ConfirmDetails handleBackBtn={handleBackBtn} />
          )}
        </div>
      </div>
    </div>
  );
}
