import React, { useState, useEffect, useRef } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useSelector } from "react-redux";
import { DownloadIcon } from "@heroicons/react/solid";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

import FileViewerModal from "components/lib/Shared/FileViewerModal";
import SpinnerIcon from "components/lib/Shared/Icons/SpinnerIcon";
import OnlyOfficeDocumentEditor from "components/lib/Shared/OnlyOfficeDocumentEditor";
import { getFullName } from "utils";
import useWidth from "hooks/useWidth";
import Button from "components/lib/Shared/Button";

const DOCS_EXTENSIONS = [
  "vnd.openxmlformats-officedocument.wordprocessingml.document",
  "docx",
];

const PDF_EXTENSIONS = ["pdf"];

const isImageFile = (extension) => {
  const imageExtensions = ["jpg", "jpeg", "png", "gif"];
  return imageExtensions.includes(extension.toLowerCase());
};

const UploadedFileView = (props) => {
  const { label, renderInModal = true, editorParams = {} } = props;
  const [filePath, setFilePath] = useState("");
  const [fileType, setFileType] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const { file } = props;

  useEffect(() => {
    if (!file) {
      setIsLoading(false);
      return;
    }
    try {
      if (typeof file === "object") {
        const fileReader = new FileReader();
        fileReader.onload = (fileLoad) => {
          const { result } = fileLoad.target;
          setFilePath(result);
          setFileType(file.type.split("/")[1]);
          setIsLoading(false);
        };
        fileReader.readAsDataURL(file);
      } else {
        const tempFileType = file.split(".").pop();
        setFileType(tempFileType);
        if (!DOCS_EXTENSIONS.includes(tempFileType)) {
          // get Blob from Url
          const request = new XMLHttpRequest();
          request.open(
            "GET",
            `${process.env.REACT_APP_IMAGE_BASE_URL}/images/${file}`,
            true
          );
          request.responseType = "blob";
          request.onload = function () {
            const reader = new FileReader();
            reader.readAsDataURL(request.response);
            reader.onload = function (e) {
              setFilePath(e.target.result);
              setIsLoading(false);
            };
          };
          request.send();
        } else {
          setFilePath(file);
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }

    return () => {
      setFilePath("");
      setFileType("");
    };
  }, [file]);

  const showLoader = isLoading && !filePath && file;
  const isDoc = DOCS_EXTENSIONS.includes(fileType);
  const isPDF = PDF_EXTENSIONS.includes(fileType);

  if (renderInModal) {
    return (
      <FileViewerModal name={props.modalName} title="Uploaded File View">
        {filePath ? (
          <Viewer
            fileType={fileType}
            filePath={filePath}
            file={file}
            isDoc={isDoc}
            isPDF={isPDF}
            editorParams={editorParams}
            label={label}
          />
        ) : (
          !showLoader && "File not found"
        )}
      </FileViewerModal>
    );
  }

  if (isLoading && !filePath && props.file)
    return (
      <div className="flex justify-center p-4">
        <SpinnerIcon className="text-primary w-6 h-6" />
      </div>
    );

  return (
    <div
      className={`block relative ${
        isDoc || (isPDF && typeof file !== "object")
          ? "overflow-hidden"
          : "overflow-auto max-h-[80vh] mx-auto"
      }`}
    >
      {filePath ? (
        <Viewer
          fileType={fileType}
          filePath={filePath}
          file={file}
          isDoc={isDoc}
          isPDF={isPDF}
          editorParams={editorParams}
          label={label}
        />
      ) : (
        !showLoader && "File not found"
      )}
    </div>
  );
};

const Viewer = ({
  fileType,
  filePath,
  file,
  isDoc,
  isPDF,
  editorParams,
  label,
}) => {
  const currentUser = useSelector((state) => state.userSlice);
  const { selectedTemplate, selectedVersion } = useSelector(
    (state) => state.templateSlice
  );
  const { company } = useSelector((state) => state.companySlice);

  const downloadImage = (url, filename) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (isImageFile(fileType)) {
    return (
      <>
        <img className="mx-auto" src={filePath} alt="File" />
        {label && (
          <Button
            overrideBaseStyles
            onClick={() => downloadImage(filePath, label)}
            className="absolute top-0 right-0 mt-1 mr-1 p-1 bg-gray-600 bg-opacity-50 rounded-full"
          >
            <DownloadIcon className="w-6 h-6 text-white" />
          </Button>
        )}
      </>
    );
  } else if (isPDF) {
    return (
      <PDFViewer
        file={file}
        filePath={filePath}
        currentUser={currentUser}
        companyId={company?._id}
        editorParams={editorParams}
      />
    );
  } else if (isDoc) {
    if (typeof file === "object")
      return (
        <span className="text-red-700">Please first save the document</span>
      );

    const templateVersions = selectedTemplate?.versions
      ? selectedTemplate.versions
      : [];
    const latestTemplate =
      templateVersions?.[templateVersions.length - 1] ?? {};

    return (
      <OnlyOfficeDocumentEditor
        userFullName={getFullName(currentUser.names)}
        userId={currentUser._id}
        companyId={company?._id}
        url={`${process.env.REACT_APP_IMAGE_BASE_URL}/templates/${file}`}
        editorKey={`${selectedTemplate._id}-${
          selectedVersion?._id || latestTemplate?._id
        }`}
        {...editorParams}
      />
    );
  } else return <span className="text-red-700">Couldn't open the file</span>;
};

const PDFViewer = ({
  file,
  filePath,
  currentUser,
  companyId,
  editorParams = {},
}) => {
  const [numPages, setNumPages] = useState(null);
  const pdfWrapper = useRef(null);
  const [width] = useWidth(pdfWrapper);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  if (typeof file === "object") {
    return (
      <div className="" ref={pdfWrapper}>
        <Document
          file={{ url: filePath }}
          onLoadSuccess={onDocumentLoadSuccess}
          loading=""
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              width={width || 300}
            />
          ))}
        </Document>
      </div>
    );
  } else {
    return (
      <OnlyOfficeDocumentEditor
        userFullName={getFullName(currentUser.names)}
        userId={currentUser._id}
        companyId={companyId}
        id={Math.random().toString(36).substring(2, 18)}
        url={`${process.env.REACT_APP_IMAGE_BASE_URL}/images/${file}`}
        editorKey={file}
        fileType="pdf"
        title=" "
        mode="view"
        showFullScreenToggle={false}
        permissions={{
          chat: false,
          comment: false,
        }}
        {...editorParams}
      />
    );
  }
};

export default UploadedFileView;
