import _forOwn from "lodash/forOwn";
import { object, string, mixed } from "yup";

const businessDocumentsValidation = (
  documentData,
  reportableBOCount,
  isCompanySecretaryExist
) => {
  const getFileValidationSchema = () => {
    const fileUploadsSchema = {};
    _forOwn(documentData, ({ name, label, optional = false }) => {
      fileUploadsSchema[name] = mixed().when("$makeFieldsOptional", {
        is: (makeFieldsOptional) =>
          makeFieldsOptional ||
          (name === "formBOF1" ? reportableBOCount === 0 : optional) ||
          (name === "formCR10" || name === "formCR12"
            ? !isCompanySecretaryExist
            : false),
        then: mixed().optional(),
        otherwise: mixed().required(`${label} is required`),
      });
    });
    return fileUploadsSchema;
  };

  const schema = object().shape({
    fileUploads: object(getFileValidationSchema()),
  });

  return {
    schema,
    schemaInitialValues: schema.cast(),
  };
};

export default businessDocumentsValidation;
